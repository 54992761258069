
import STISCUpdateReverseScan from "../../reverse-scan/sti-sc-update-reverse-scan.vue";
import STISCUpdateV2 from "./sti-sc-update-v2.vue";  
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionOutgoingShipmentSTISC } from "@/feature-flags/flags-out-going-shipment-sti-sc";

@Options({
  components: {
    STISCUpdateReverseScan,
    STISCUpdateV2,
  },
})
export default class STISCList extends Vue {
  get isReverseScanSTISC() {
    return FlagsPermissionOutgoingShipmentSTISC.reverse_scan_sti_sc_enable.isEnabled();
  }
}
