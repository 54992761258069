
  import STISCListReverseScan from "./reverse-scan/sti-sc-list-reverse-scan.vue";
  import STISCListV1 from "./sti-sc-old-list.vue";  
  import { Options, Vue } from "vue-class-component";
  import { FlagsPermissionOutgoingShipmentSTISC } from "@/feature-flags/flags-out-going-shipment-sti-sc";

  @Options({
    components: {
      STISCListReverseScan,
      STISCListV1,
    },
  })
  export default class STISCList extends Vue {
    get isReverseScanSTISC() {
      return FlagsPermissionOutgoingShipmentSTISC.reverse_scan_sti_sc_enable.isEnabled();
    }
  }
  