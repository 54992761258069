
import { STISCScanFailed } from "@/domain/entities/StiSc";
import { prop, Vue } from "vue-class-component";
import XLSX from "xlsx";

export class Props {
  data = prop<STISCScanFailed[]>({
    default: () => [],
    required: true,
    type: Array
  });
}

export default class ScanFailedCard extends Vue.with(Props) {
  XLSX = XLSX;
  downloadExcel() {
    const data = this.XLSX.utils.json_to_sheet(
      this.data.map(d => ({
        "Nomor STT": d.sttNo,
        "Alasan gagal scan": d.errorMessage
      }))
    );
    const wb = this.XLSX.utils.book_new();
    this.XLSX.utils.book_append_sheet(wb, data, "STT gagal scan");
    this.XLSX.writeFile(wb, "STT gagal scan.xlsx");
  }
}
