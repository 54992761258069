
import { Options, Vue } from "vue-class-component";
import { FlagsPermissionOutgoingShipmentSTISC } from "@/feature-flags/flags-out-going-shipment-sti-sc";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import PrintPDF from "../component/update/index.vue";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import debounce from "lodash/debounce";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import router from "@/app/ui/router";

@Options({
  components: {
    EmptyState,
    PrintPDF
  },
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
    ableToCreate() {
      return FlagsPermissionOutgoingShipmentSTISC.permission_sti_sc_create.isEnabled();
    }
  }
})
export default class STISCListReverseScan extends Vue {
  refs: any = this.$refs;
  intervalId: any = "";

  beforeMount() {
    this.fetchNeedStiSCSummary();
  }

  mounted() {
    this.intervalId = setInterval(() => {
      this.fetchNeedStiSCSummary();
    }, 600000); // 600000 millisecond = 10 minute
  }

  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    StiSCController.setPeriodeStart("" as any);
    StiSCController.setPeriodeEnd("" as any);
    StiSCController.setSearch("");
    StiSCController.setFirstPage();
  }

  // STI SC summary

  fetchNeedStiSCSummary() {
    StiSCController.getSTISCSTTDueSummary();
  }

  onRefreshSTISCSummary = debounce(() => {
    this.fetchNeedStiSCSummary();
  }, 250);

  get STISCSTTDueSummaryData() {
    return StiSCController.STISCSTTDueSummaryData;
  }

  get isLoadingSTTSummary() {
    return StiSCController.isLoadingSTTSummary;
  }

  gotoNeedToStiSCPage() {
    // to do
    router.push({
      name: "need-to-sti-sc-booking",
    });
  }

  // STI SC list

  fetchStiSCList() {
    StiSCController.fetchStiSCList({isStoSc: false});
  }

  get pagination() {
    return StiSCController.stiSCList.pagination;
  }

  get stiSCList() {
    return StiSCController.stiSCList.data;
  }

  get firstColumns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (item: any, index: any) => {
          return `<div class="text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "ID manifest",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-left">${item.stiSCId}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalStt}</div>`;
        }
      }
    ];
  }

  get secondColumns() {
    return [
      {
        name: "Total berat",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiSCTotalGrossWeight,
            2
          )} kg</div>`;
        }
      },
      {
        name: "Total koli",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalPieces}</div>`;
        }
      },
      {
        name: "Tanggal manifest",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="flex flex-col overflow-ellipsis text-left"><span>${this.formatDateCreated(
            item.stiSCCreatedAt
          )}</span> <span class="text-xs ${
            item.stiSCCreatedName ? "text-gray-lp-500" : "text-white"
          }">${item.stiSCCreatedName || "User"}</span></div>`;
        }
      }
    ];
  }

  get columnsAction() {
    return [
      {
        name: "Atur",
        key: "button_column",
        styleHead: "w-36 text-left",
        styleButton: (item: any) => {
          let groupOptions = [] as any[];
          const printPdf = {
            icon: "printer",
            label: this.$t("Print PDF sudah STI SC"),
            clickFunction: () => this.printManifest(item.stiSCId)
          };

          const downloadStt = {
            icon: "download-gray",
            label: this.$t("Download STT belum STI SC"),
            clickFunction: () => {
              StiSCController.downloadSTISCExcel(item.stiSCId)
            }
          };
          if (item.hasSttNeedStiSC) {
            groupOptions = [
              printPdf,
              downloadStt
            ];
          } else {
            groupOptions = [printPdf];
          }
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            clickFunction: () => null,
            groupOptions: groupOptions
          };
        }
      }
    ];
  }

  get columns() {
    return [...this.firstColumns, ...this.secondColumns, ...this.columnsAction];
  }

  // loading
  get isLoading() {
    return StiSCController.isLoading;
  }

  get errorCause() {
    return StiSCController.errorCause;
  }

  // filter search
  get searchValue() {
    return StiSCController.search;
  }

  onSearch(value: string) {
    StiSCController.setSearch(value);
    StiSCController.setFirstPage();
    this.fetchStiSCList();
  }

  clearSearch() {
    StiSCController.setSearch("");
    this.fetchStiSCList();
  }

  // filter date
  get periodeStart() {
    return StiSCController.periodeStart;
  }

  get periodeEnd() {
    return StiSCController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    StiSCController.setPeriodeStart(value[0]);
    StiSCController.setPeriodeEnd(value[1]);
    if (value[0] && value[1]) {
      StiSCController.setFirstPage();
      this.fetchStiSCList();
    }
  }

  formatSTTNumber(value: string) {
    return formatInputMoney(value.replace(/\D+/g, ""));
  }

  formatDateCreated(date: string) {
    return formatDate(date);
  }

  // goToCreate
  goToUpdate() {
    this.$router.push("/sti-sc/update");
  }

  async printManifest(id: number) {
    // to do
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti-sc/component/modules/generate-reverse-scan.vue"
    );
    print.default.methods.printManifest(id);
  }
}
