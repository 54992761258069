
import { Options, Vue, prop } from "vue-class-component";
import InvalidIcon from "./image/invalid.vue";
import STISCIcon from "./image/sti-sc.vue";

class Props {
  isInvalid = prop<boolean>({
    type: Boolean,
    default: false,
  });
}

@Options({
  components: {
    InvalidIcon,
    STISCIcon,
  }
})
export default class STISCReverseScanIllustration extends Vue.with(Props) {
  get decideSTTIconComponent(): string {
    if (this.isInvalid) return "InvalidIcon";
    return "STISCIcon";
  }
}
