
import { Options, Vue } from "vue-class-component";
import {
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { StiSCController } from "@/app/ui/controllers/StiSCController";
import PrintPDF from "./component/update/index.vue";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { generateManifest2ColumnPotrait } from "@/app/ui/modules/manifest-v2/potrait";
import {
  ColumnManifestData,
  HeaderManifestData,
  SectionManifestData
} from "@/domain/entities/MainApp";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { FlagsPermissionOutgoingShipmentSTISC } from "@/feature-flags/flags-out-going-shipment-sti-sc";
@Options({
  components: {
    PrintPDF,
    EmptyState
  },
  computed: {
    getTitle() {
      return this.$route.meta.title;
    },
    ableToCreate() {
      return FlagsPermissionOutgoingShipmentSTISC.permission_sti_sc_create.isEnabled();
    }
  }
})
export default class StiSC extends Vue {
  refs: any = this.$refs;

  mounted() {
    this.refs = this.$refs;
  }

  fetchStiSCList() {
    StiSCController.fetchStiSCList({isStoSc: false});
  }

  // loading
  get isLoading() {
    return StiSCController.isLoading;
  }

  get errorCause() {
    return StiSCController.errorCause;
  }

  // filter date
  get periodeStart() {
    return StiSCController.periodeStart;
  }

  get periodeEnd() {
    return StiSCController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    StiSCController.setPeriodeStart(value[0]);
    StiSCController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchStiSCList();
  }

  // goToCreate
  goToUpdate() {
    this.$router.push("/sti-sc/update");
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (item: any, index: any) => {
          return `<div class="text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "Nama Sub Consolidator",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-left">${item.stiSCPartnerName}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalStt}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.stiSCTotalPieces}</div>`;
        }
      },
      {
        name: "Total Berat Kotor",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiSCTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Total Berat Volume",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiSCTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal STI-SC",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="flex flex-col overflow-ellipsis text-left"><span>${this.formatDateCreated(
            item.stiSCCreatedAt
          )}</span> <span class="text-xs ${
            item.stiSCCreatedName ? "text-gray-lp-500" : "text-white"
          }">${item.stiSCCreatedName || "User"}</span></div>`;
        }
      },
      {
        name: "Aksi",
        key: "button_column",
        styleHead: "w-36 text-left",
        styleButton: (item: any) => {
          return {
            outline: true,
            title: "Cetak",
            textColor: "black",
            color: "gray-lp-400",
            small: false,
            groupOptionsClass: "sti-sc-group-options",
            clickFunction: () => {
              this.printManifest("V2", item);
            }
          };
        }
      }
    ];
  }

  get stiScIdToPrint() {
    return StiSCController.stiScIdToGeneratePdf;
  }

  formatDateCreated(date: string) {
    return formatDate(date);
  }

  async printManifest(version: any, obj: any) {
    if (version === "V2") {
      MainAppController.showLoading();
      await StiSCController.getStiSCManifestDetail({ id: obj.stiSCId });
      const dataManifest = StiSCController.stiSCManifestData;
      const data = new SectionManifestData({
        featureName: "STI-SC",
        headerDetailData: new HeaderManifestData({
          createdAt: dataManifest.stiCreatedAt,
          totalPiece: dataManifest.stiTotalPieces,
          totalGrossWeight: dataManifest.stiTotalGrossWeight
        }),
        data: dataManifest.stt.map(
          (item: any) =>
            new ColumnManifestData({
              sttNo: item.sttNo,
              totalGrossWeight: item.sttGrossWeight,
              totalPiece: item.sttTotalPiece
            })
        ),
        signature: true
      });
      generateManifest2ColumnPotrait(data);
      MainAppController.closeLoading();
      return;
    }
    StiSCController.setStiScIdToGeneratePdf(obj.stiSCId);
    this.refs.print.print(obj.stiSCId);
  }

  get isLoadingPrintList() {
    return StiSCController.isLoadingPrint;
  }

  get isErrorPrintList() {
    return StiSCController.isErrorPrint;
  }

  get errorCausePrintList() {
    return StiSCController.errorPrintCause;
  }

  closeModalErrorPrintList() {
    StiSCController.setErrorPrint(false);
    StiSCController.setErrorPrintCause("");
  }

  get pagination() {
    return StiSCController.stiSCList.pagination;
  }

  get stiSCList() {
    return StiSCController.stiSCList.data;
  }
}
